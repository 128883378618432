import React from "react";
import GradientText from "../../components/ui/gradient-text";
import Button from "../../components/ui/button";
import Newsletter from "../../components/newsletter/newsletter";
//adding react-helmet
import { Helmet } from "react-helmet";

const Home = () => {
  const images = [
    //"/assets/images/home/b1.png",
    "/assets/images/home/b2.png",
    //"/assets/images/home/b3.png",
  ];
  return (
    <div className="w-full relative">
      <Helmet>
        <title>DeltaClaims.AI | AI-Driven Claims Adjustment and Management Services</title>
        <meta name="description" content="DeltaClaims.AI offers cloud-native, AI-powered solutions for insurance claims adjustment and management." />
        <meta name="keywords" content="AI claims adjustment, insurance claims automation, cloud-native claims processing, machine learning in insurance, DeltaClaims AI" />
        <meta property="og:title" content="DeltaClaims.AI - Revolutionizing Claims Management with AI" />
        <meta property="og:description" content="AI-powered claims adjustment solutions for faster, more efficient claims management." />
        <meta property="og:url" content="https://www.deltaclaims.ai" />
        <meta property="og:image" content="https://www.deltaclaims.ai/assets/images/logo.png" />
        <meta name="robots" content="index,follow" />
      </Helmet>
      <img
        alt="banner"
        src="/assets/images/home/home-banner-shadow.png"
        className="absolute left-0 top-0 z-50 hidden lg:block"
      />
      <div className="w-full relative">
        <img
          alt="banner"
          src="/assets/images/team/team-shape-banner.png"
          className="w-full absolute -bottom-12 lg:bottom-0 lg:relative z-10 lg:h-[900px]"
        />
        <div className="w-full p-4 bg-[#001235] lg:bg-transparent lg:absolute top-0 left-0 right-0 mx-auto h-full z-10">
          <div className="w-full max-w-screen-2xl mx-auto flex items-center flex-wrap md:flex-nowrap h-full">
            <div className="w-full lg:w-5/12 text-white text-center lg:text-left relative z-10">
              <GradientText
                classes={"uppercase font-bold text-xl lg:text-2xl"}
                text={"welcome to deltaclaims.ai"}
              ></GradientText>
              <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] capitalize leading-9 lg:leading-[70px] mb-4 lg:my-8">
                we provide an
                <span className="font-bold"> ai </span>
                <span className="font-bold relative mr-2">
                  solution
                  <img
                    alt="line"
                    src="/assets/images/home/line-through.png"
                    className="absolute left-0 top-0 w-full z-[-1]"
                  />
                </span>
                for the insurance industry
              </h1>
              <Button
                fill={true}
                text={"get started with us"}
                type={"button"}
                icon={true}
              />
            </div>
            <div className="w-full lg:w-6/12">
              <img className="w-full h-full relative z-10" alt="banner" src="/assets/images/team/team-banner.png" />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full border-b-2 border-[#d9d9d9]">
        <div className="w-full max-w-screen-xl mx-auto py-10 lg:py-0 my-10 p-4">
          <GradientText
            classes={"uppercase text-center font-bold text-lg lg:text-[22px]"}
            text={"trusted by leading companies"}
          ></GradientText>
        </div>
        {/* removed image and added coming soon text*/}
        <div className="w-full max-w-screen-xl mx-auto py-10 lg:py-0 my-10 p-4">
          <GradientText
            classes={"text-center font-bold text-lg lg:text-[22px]"}
            text={"Coming Soon"}
          ></GradientText>
        </div>
      </div>

      <div className="w-full relative my-10 lg:my-[70px] p-4 lg:p-0">
        <img
          src="/assets/images/home/home-right-shape.png"
          className="absolute right-0 top-0"
          alt="shape"
        />

        <div className="flex flex-wrap lg:flex-nowrap items-center w-full max-w-[1800px] mx-auto relative z-30">
          <div className="w-full lg:w-7/12 relaive z-50">
            <img
              className=""
              src="/assets/images/home/banner-2.png"
              alt="text"
            />
          </div>
          <div className="w-fu lg:w-4/12 text-left">
            <div className="flex gap-2 items-center">
              <img src="/assets/images/home/color-favi.png" alt="text" />
              <GradientText
                classes={"uppercase text-center font-medium text-xl lg:text-[22px]"}
                text={"about us"}
              ></GradientText>
            </div>
            <h2 className="text-2xl lg:text-[50px] font-['Montserrat'] leading-9 lg:leading-[55px] py-3 ">
              Get to Know About
              <br />
              <span className="font-bold">DeltaClaims.AI</span>
            </h2>
            <p className="text-base font-['Montserrat] pb-[30px] lg:pr-[120px]">
              Our purpose is to empower claims professionals through innovative,
              tailor-made AI solutions. Our AI simplifies the complex world of
              insurance claims assisting insurance professionals and general 
              contractors with efficient, secure, and accurate information about
              their claim.
            </p>
            <Button
              fill={true}
              text={"get access"}
              type={"button"}
              icon={true}
            />
          </div>
        </div>
      </div>

      <div className="w-full max-w-screen-xl mx-auto my-10 relative z-50 p-4 lg:p-0">
        <Newsletter />
      </div>

      <img
        src="/assets/images/home/home-bottom-shape-2.png"
        className="absolute left-0 bottom-[200px]"
        alt="shape"
      />
      <img
        src="/assets/images/home/home-bottom-shape.png"
        className="absolute left-0 bottom-0"
        alt="shape"
      />
    </div>
  );
};

export default Home;
