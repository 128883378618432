import React from "react";
import Button from "../../components/ui/button";
import GradientText from "../../components/ui/gradient-text";
import { NavLink } from "react-router-dom";

const OurSolution = () => {

    const sequence = [
        {
            icon: "/assets/images/our-solution/icon1.png",
            number: "/assets/images/our-solution/1.png",
            text: "Create an account",
            list: [
                "Choose your plan",
                "Pay for your plan",
                "Welcome email with your login information and training materials",
                "24x7 by clicking Get Access"
            ],
        },
        {
            icon: "/assets/images/our-solution/icon2.png",
            number: "/assets/images/our-solution/2.png",
            text: "Login",
            list: [
                "Enforces two-factor authentication",
                "Your customer’s claim information is important and so we take the security of this information seriously..."
            ],
        },
        {
            icon: "/assets/images/our-solution/icon3.png",
            number: "/assets/images/our-solution/3.png",
            text: "Create a new claim for your customers",
            list: [
                "Each claim is assigned a unique claim number",
                "Each claim is segmented from other claims in the system",],
        },
        {
            icon: "/assets/images/our-solution/icon4.png",
            number: "/assets/images/our-solution/4.png",
            text: "Upload Your Files",
            list: [
                "There is no limitations on file sizes or types.  No need to organize, name, or change the format of files before you upload, our AI engine will classify and organize your files."
            ],
        },
        {
            icon: "/assets/images/our-solution/icon5.png",
            number: "/assets/images/our-solution/5.png",
            text: "Ask Questions",
            list: [
                "Ask the AI bot anything you want about your claim!",
                "Our regular webcasts provide tips and tricks for getting right to the information you want",
                "Call our team of experts 24/7"
            ],
        },
        {
            icon: "/assets/images/our-solution/icon6.png",
            number: "/assets/images/our-solution/6.png",
            text: "Drill down to the information you need to know about the file.",
            list: ["Quickly find details about the claim or search for particular information"],
        },
        {
            icon: "/assets/images/our-solution/icon7.png",
            number: "/assets/images/our-solution/7.png",
            text: "Create emails, letters, tasks, and calendar items from within the tool.",
            list: ["Extracts due dates and deadlines from the claim files"],
        },
        {
            icon: "/assets/images/our-solution/icon8.png",
            number: "/assets/images/our-solution/8.png",
            text: "The AI automatically creates summaries and reports.",
            list: [
                "Claims summaries",
                "Organizes list of estimates",
                "Damage/Repair protocols",
                "Delta Reports and Analysis"],
        },
    ]

    const conferences = [
        {
            imgSrc: "/assets/images/our-solution/roof-con-logo-mod.png",
            title: "ROOFCON 2024",
            description: '"RoofCON was created for forward-thinkers, trailblazers, top performers, and business owners in the roofing and solar industries who seek to define the marketplace today and tomorrow. In just a few years, RoofCON has become the #1 conference for the roofing and solar community! At RoofCON, we focus on GROWING both personally and professionally. We provide proven systems and next-level strategies to help our attendees develop as leaders." RoofCon',
            location: "Orange County Convention Center, Orlando, FL",
            info: {
                date: "Oct 23 - 25, 2024",
                website: "https://roofcon.com",
                demos: "All Day"
            }
        },
        {
            imgSrc: "/assets/images/our-solution/windstorm-logo.png",
            title: "WINDSTORM 2024",
            description: '"Windstorm Conference brings together leaders, experts, and innovators in the field of windstorm mitigation and response. The event aims to share the latest advancements, best practices, and strategies for dealing with windstorm-related challenges. Attend and gain valuable insights to better prepare and protect your communities." Windstorm Insurance Network',
            location: "Hyatt Regency Dallas, Dallas, TX",
            info: {
                date: "January 26 - 29, 2025",
                website: "https://windnetwork.swoogo.com/wind2025",
                demos: "All Day"
            }
        }
    ];

    return (
        <div className="w-full relative bg-[#f3fff4]">
            <img
                alt="banner"
                src="/assets/images/home/home-banner-shadow.png"
                className="absolute left-0 top-0 z-[100] hidden xl:block"
            />
            <div className="w-full relative">
                <img
                    alt="banner"
                    src="/assets/images/team/team-shape-banner.png"
                    className="w-full absolute -bottom-[55px] lg:bottom-0 lg:relative z-10 lg:h-[750px]"
                />
                <div className="w-full p-4 bg-[#001235] lg:bg-transparent lg:absolute top-0 left-0 right-0 mx-auto h-full z-10">
                    <div className="w-full max-w-screen-xl mx-auto flex items-center flex-wrap md:flex-nowrap h-full justify-center">
                        <div className="w-full lg:w-5/12 text-white text-left relative z-10">
                            <h1 className="text-2xl text-center lg:text-left lg:text-[60px] font-['Montserrat'] capitalize leading-[70px] mb-4 lg:mb-8">
                                see it in
                                <span className="font-bold relative ml-3">
                                    Action
                                    <img
                                        src="/assets/images/home/line-through.png"
                                        alt="sol"
                                        className="absolute left-0 top-0 w-full h-full z-[-1]"
                                    />
                                </span>
                            </h1>
                            <Button
                                fill={true}
                                text={"get started with us"}
                                type={"button"}
                                icon={true}
                            />
                        </div>
                        <div className="w-full relative z-10 lg:w-6/12 pt-4 lg:pt-[100px]">
                            <img
                                alt="banner"
                                src="/assets/images/team/team-banner.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-full pt-[50px] relative">
                <img src="/assets/images/our-solution/wave-right.png" alt="wave-right" className="absolute -top-[200px] right-0" />
                <img src="/assets/images/our-solution/half-circle.png" alt="wave-right" className="absolute top-[200px] right-0" />
                <img src="/assets/images/our-solution/left-wave.png" alt="left-wave" className="absolute top-[50%] left-0" />
                <div className="w-full max-w-screen-xl mx-auto relative z-10 p-4 xl:p-0">
                    <div className="w-full flex items-center justify-between flex-wrap lg:flex-nowrap">
                        <div className="text-center lg:text-left w-full">
                            <div className="w-full flex items-center justify-center lg:justify-start gap-2">
                                <img src="/assets/images/home/color-favi.png" alt="favi" />
                                <GradientText
                                    classes={"uppercase text-center font-medium text-xl lg:text-[22px]"}
                                    text={"deltaclaims.ai"}
                                />
                            </div>
                            <h2 className="text-2xl lg:text-[50px] text-black font-['Montserrat'] lg:leading-[55px] py-3">
                                How It
                                <span className="font-bold ml-3">Works</span>
                            </h2>
                            <p className="font-['Montserrat'] text-base">We will take care of the complicated parts, so you do not have to:</p>
                        </div>
                        <div className="flex flex-wrap xl:flex-nowrap items-center gap-4 w-full justify-center lg:justify-end mt-4 lg:mt-0">
                            <div
                                style={{
                                    "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                    "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                    "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                    "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                }} className="p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-[250px] h-[49px] rounded-full flex-shrink-0 mx-auto lg:mx-0">
                                <NavLink to="/what-is-ai">
                                    <button className="w-full bg-[#f3fff4] items-center focus:outline-none p-2 rounded-full text-white w-full h-full text-base font-['Poppins'] font-medium capitalize transition-all duration-300 flex-shrink-0 flex items-center justify-center gap-x-2">
                                        <img src="/assets/images/home/color-favi.png" alt="img" />
                                        <GradientText classes={"tracking-normal normal-case text-base font-['Poppins']"} text={'What is AI'} />
                                    </button>
                                </NavLink>
                            </div>
                            <Button
                                type={"button"}
                                text={"get access"}
                                fill={true}
                                icon={true}
                            />
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 w-full gap-[24px] pt-[50px] relative">
                        <img src="/assets/images/our-solution/arrows.png" alt="arrows" className="hidden 2xl:block absolute top-[200px] -right-[55px]" />
                        {
                            sequence.map((data, index) => {
                                return (
                                    <div
                                        style={{
                                            "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                            "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                            "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                            "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                        }} key={index} className="p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-full rounded-2xl">
                                        <div className="w-full bg-white rounded-2xl h-full p-[25px] text-left">
                                            <div className="flex justify-between">
                                                <img className="w-[70px] h-[70px] object-contain" src={data.icon} alt={data.icon} />
                                                <img className="h-[90px] object-contain" src={data.number} alt={data.number} />
                                            </div>
                                            <p className="font-semibold font-['Montserrat'] text-[20px] mt-6 mb-4">
                                                {data.text}
                                            </p>
                                            {
                                                data.list.length > 0 && data.list.map((li, liIndex) => {
                                                    return (
                                                        <div key={liIndex} className="flex gap-3 mb-2">
                                                            <div style={{
                                                                "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                                                "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                                                "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                                                "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                                            }} className="h-2 w-2 rounded-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] flex-shrink-0 mt-2"></div>
                                                            <p className="text-base font-['Montserrat']">{li}</p>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="w-full pt-[120px] pb-[40px]">
                        <div className="w-full text-center flex items-center justify-center gap-2 mb-2">
                            <img src="/assets/images/home/color-favi.png" alt="favi" />
                            <GradientText
                                classes={"uppercase text-center font-medium text-xl lg:text-[22px]"}
                                text={"conferences"}
                            />
                        </div>
                        <h2 className="text-2xl lg:text-[50px] text-black font-['Montserrat'] leading-[55px] pb-6">
                            See It In
                            <span className="font-bold ml-3">Action</span>
                        </h2>
                        {
                            conferences.map((conference, index) => {
                                const conferenceClass = conference.title === "WINDSTORM 2024" ? "windstorm-conference" : "";
                                
                                return (
                                    <div 
                                        style={{
                                            "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                            "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                            "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                            "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                        }} 
                                        key={index} 
                                        className={`p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-full rounded-2xl mb-[20px] ${conferenceClass}`}
                                    >
                                        <div className="w-full flex flex-wrap lg:flex-nowrap gap-[20px] justify-between bg-white rounded-2xl h-full p-4 lg:p-[30px] text-left">
                                            <img className="w-full lg:w-[300px] h-auto object-contain rounded-2xl" src={conference.imgSrc} alt={conference.title} />
                                            <div className="grid lg:grid-cols-2 xl:grid-cols-3 gap-[25px] xl:divide-x divide-[#e5e5e5]">
                                                <div className="w-full lg:pl-[25px] lg:pt-[30px]">
                                                    <h3 className="font-bold text-[18px] font-['Montserrat'] mb-3">
                                                        {conference.title}
                                                    </h3>
                                                    <p className="text-base font-['Montserrat'] break-words">
                                                        {conference.description}
                                                    </p>
                                                </div>
                                                <div className="w-full lg:pl-[25px] lg:pt-[30px]">
                                                    <h3 className="font-bold text-[18px] font-['Montserrat'] mb-3">
                                                        Location
                                                    </h3>
                                                    <div className="flex gap-2 items-center mb-2">
                                                        <p className="text-base font-['Montserrat']">{conference.location}</p>
                                                    </div>
                                                </div>
                                                <div className="w-full lg:pl-[25px] lg:pt-[30px]">
                                                    <h3 className="font-bold text-[18px] font-['Montserrat'] mb-3">
                                                        Conference Info
                                                    </h3>
                                                    <div className="flex gap-2 items-center mb-2">
                                                        <p className="text-base font-['Montserrat']">{conference.info.date}</p>
                                                    </div>
                                                    <div className="flex gap-2 items-center mb-2">
                                                        <p className="text-base font-['Montserrat'] break-words">
                                                            <a
                                                                href={conference.info.website}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="text-blue-500 underline"
                                                            >
                                                                {conference.info.website}
                                                            </a>
                                                        </p>
                                                    </div>
                                                    <div className="flex gap-2 items-center mb-2">
                                                        <p className="text-base font-semibold font-['Montserrat']">Live Demos:</p>
                                                        <p className="text-base font-['Montserrat']">{conference.info.demos}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="w-full pb-[100px] flex justify-center">
                        <Button
                            type={"button"}
                            text={"get access"}
                            fill={true}
                            icon={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OurSolution;