import React, { useState } from "react";
import GradientText from "../../components/ui/gradient-text";
import Modal from 'react-modal';

// Set the root element for the modal
Modal.setAppElement('#root');

const TestPayments = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [formData, setFormData] = useState({
    cardNumber: '',
    expDate: '',
    cvc: '',
    name: '',
    email: '',
    phone: '',
    company: '',
    website: '',
  });

  // Define the plans
  const plans = [
    {
      title: "ProOwl",
      subtitle: "Professional",
      price: "$115",
      includes: [
        "700 Pages Per Project",
        "6 Users",
        "AI Bot",
        "DeltaMate Report",
        "DeltaPolicy Report",
        "DeltaCase Summary",
        "Custom Branding",
        "AI Analytics Business Report (Coming 2025)",
        "Generate Emails (Coming 2025)",
        "Generate Letters (Coming 2025)",
        "Custom Training",
        "Dedicated Phone Support Number",
        "Building Supplies API (Coming 2025)",
      ],
      buttonText: "Pay",
    },
    {
      title: "AdvOwl",
      subtitle: "Advanced",
      price: "$129",
      includes: [
        "600 Pages Per Project",
        "3 Users",
        "AI Bot",
        "DeltaMate Report",
        "DeltaPolicy Report",
        "DeltaCase Summary",
        "Custom Branding",
        "AI Analytics Business Report (Coming 2025)",
        "Generate Emails (Coming 2025)",
        "Generate Letters (Coming 2025)",
      ],
      buttonText: "Pay",
    },
    {
      title: "BasicOwl",
      subtitle: "Basic",
      price: "$139",
      includes: [
        "500 Pages Per Project",
        "1 User",
        "AI Bot",
        "DeltaMate Report",
        "DeltaPolicy Report",
        "DeltaCase Summary",
      ],
      buttonText: "Pay",
    },
    {
      title: "EntOwl",
      subtitle: "Enterprise",
      price: "Contact us",
      includes: ["Customized to your business needs"],
      buttonText: "Contact us",
    },
  ];

  // Open the modal and set the selected plan
  const openModal = (plan) => {
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  // Close the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Handle form changes
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // Mock webhook submission with form data
    console.log('Submitting payment information', formData);

    // Example: Send a mock request to the webhook
    fetch('YOUR_WEBHOOK_URL', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        plan: selectedPlan.title,
        ...formData,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);
        closeModal();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  // Render the component
  return (
    <div className="w-full bg-[#f3fff4] relative">
      {/* Modal Section */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            background: 'white',
            padding: '30px',
            borderRadius: '10px',
            width: '90%',
            maxWidth: '500px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            zIndex: '10000',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: '9999',
          },
        }}
      >
        <h2 className="text-2xl font-bold mb-6 text-center">
          Pay for {selectedPlan?.title}
        </h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block mb-2 font-medium text-gray-700">Card Number</label>
            <input
              type="text"
              name="cardNumber"
              value={formData.cardNumber}
              onChange={handleFormChange}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              placeholder="1234 5678 9012 3456"
            />
          </div>
          <div className="flex space-x-4">
            <div className="w-1/2">
              <label className="block mb-2 font-medium text-gray-700">Expiration Date</label>
              <input
                type="text"
                name="expDate"
                value={formData.expDate}
                onChange={handleFormChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="MM/YY"
              />
            </div>
            <div className="w-1/2">
              <label className="block mb-2 font-medium text-gray-700">CVC</label>
              <input
                type="text"
                name="cvc"
                value={formData.cvc}
                onChange={handleFormChange}
                required
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                placeholder="123"
              />
            </div>
          </div>
          <div>
            <label className="block mb-2 font-medium text-gray-700">Name on Card</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleFormChange}
              required
              className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              placeholder="Your Full Name"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-3 rounded-lg transition duration-300"
          >
            Submit Payment
          </button>
        </form>
        <button
          onClick={closeModal}
          className="w-full bg-red-500 text-white font-bold py-2 rounded-lg mt-4"
        >
          Close
        </button>
      </Modal>
      <div className="w-full relative">
        <img
          alt="banner"
          src="/assets/images/testimonial/testimonial-banner.png"
          className="w-full relative h-[150px] lg:h-[550px] hidden xl:block"
        />
        <img
          alt="banner"
          src="/assets/images/common-banner.png"
          className="w-full relative h-[150px] lg:h-[550px] xl:hidden"
        />
        <div className="w-full p-4 absolute top-0 lg:top-[250px] left-0 right-0 mx-auto">
          <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] leading-[70px] text-white inline-block relative">
            Test
            <span className="font-bold relative ml-3 z-10">
              Payments
              <img
                alt="line"
                src="/assets/images/blog/line-through.png"
                className="w-full absolute top-[-6px] lg:-top-[20px] left-0 z-[-1]"
              />
            </span>
          </h1>
        </div>
      </div>

      <div className="w-full relative z-10 mt-8">
          <GradientText
            classes={"uppercase text-center font-bold text-lg lg:text-[22px]"}
            text={"choose your plan"}
          ></GradientText>
      </div>

      <div className="w-full max-w-screen-xl mx-auto lg:mt-24 relative z-10 p-4 xl:p-0">
        <div className="flex flex-wrap gap-6 lg:gap-0 lg:flex-nowrap pt-8 pb-20 justify-center relative z-10">
          {plans.map((plan, index) => (
            <div
              key={index}
              className="bg-[#022b51] text-white rounded-lg p-6 w-full max-w-sm flex flex-col justify-start shadow-lg"
            >
              <h3 className="text-xl font-semibold">{plan.title}</h3>
              <h4 className="text-lg font-light mb-4">{plan.subtitle}</h4>
              <p className="text-4xl font-bold mb-6">{plan.price}</p>
              <button
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded mb-4"
                onClick={() => openModal(plan)}
              >
                {plan.buttonText}
              </button>
              <ul className="list-none text-left">
                {plan.includes.map((item, idx) => (
                  <li key={idx} className="mb-2">
                    <span>✓</span> {item}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestPayments;
