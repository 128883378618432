import Button from "../../components/ui/button";
import GradientText from "../../components/ui/gradient-text";
import React from "react";

const Blogs = () => {
  const blogs = [
    "/assets/images/blog/blog2.png",
    "/assets/images/blog/blog3.png",
    "/assets/images/blog/blog4.png",
  ];

  return (
    <div className="w-full bg-[#f3fff4] pb-[200px] relative">
      <div className="w-full relative">
        <img
          alt="banner"
          src="/assets/images/blog/blog-banner.png"
          className="w-full relative h-[200px] lg:h-[780px] hidden xl:block"
        />
        <img
          alt="banner"
          src="/assets/images/common-banner.png"
          className="w-full relative h-[150px] lg:h-[550px] xl:hidden"
        />
        <div className="w-full p-4 absolute top-0 lg:top-[250px] left-0 right-0 mx-auto">
          <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] leading-[70px] text-white inline-block relative">
            Recent
            <span className="font-bold relative ml-3 relative z-10">
              Blogs
              <img
                src="/assets/images/blog/line-through.png"
                className="absolute right-0 top-0 w-full h-full z-[-1]"
              />
            </span>
          </h1>
        </div>
        <img src="/assets/images/blog/half-circle-shape.png" className="absolute -bottom-[300px] right-0" alt="shape" />
      </div>

      <div className="w-full xl:-mt-[150px] p-4">
        <div className="text-center">
          <img
            src="/assets/images/blog/color-favi.png"
            alt="color-favi"
            className="block mx-auto lg:w-12 mb-4"
          />
          <GradientText
            classes={"uppercase text-center font-medium text-xl lg:text-[22px]"}
            text={"our blogs"}
          ></GradientText>
          <h2 className="text-2xl lg:text-[50px] font-['Montserrat'] lg:leading-[55px] p-4 ">
            Our Latest <span className="font-bold">Blogs</span>
          </h2>
        </div>
      </div>

      <div className="w-full p-4 lg:p-0">
        <div className="w-full max-w-screen-xl mx-auto relative">
          <img src="/assets/images/blog/circular-dotted-shape.png" alt="shape" className="absolute -top-[200px] -left-[200px]" />
          <div style={{
            "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
            "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
            "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
            "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
          }} className="w-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] p-0.5 rounded-3xl">
            <div className="bg-white rounded-3xl flex flex-wrap lg:flex-nowrap gap-4 lg:gap-[50px]">
              <img
                src="/assets/images/blog/blog1.png"
                alt="blog1"
                className="w-full lg:w-6/12 rounded-t-3xl lg:border-l-3x"
              />
              <div className="w-full lg:w-6/12 text-left p-4 lg:py-[60px] lg:pr-[60px]">
                <p
                  style={{
                    "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "rgba(255,255,255,0)",
                  }}
                  className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] inline-block text-transparent 
                bg-clip-text text-[20px] font-semibold font-['Poppins']"
                >
                  Coming in September 2024
                </p>
                <h2 className="font-medium text-xl lg:text-[35px] font-['Montserrat'] lg:leading-[46px] mt-4 lg:mt-6">
                  Reduce Claims Processing from Days to Minutes
                </h2>
                <p className="text-base font-medium font-['Montserrat'] my-6">
                  AI technology is going to revolutionize the claims processing industry by significantly reducing the time it takes to process claims. With AI, the entire process, from classifying to analyzing documents, can be automated, eliminating the need for manual intervention. 
                  This automation not only speeds up the process but also improves accuracy and efficiency. AI algorithms can quickly and accurately classify different types of claims documents, such as estimates, insurance policies, and expert reports. Once classified, AI can analyze the documents to extract relevant information, identify patterns, and make informed decisions. By leveraging AI, organizations can streamline their claims processing workflows, reduce errors, and provide faster and more accurate claim settlements to their customers.
                </p>
                <Button
                  fill={true}
                  text={"read more"}
                  type={"button"}
                  icon={false}
                />
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-3 mt-[20px] gap-[20px] relative z-40">
            {blogs.map((map) => {
              return (
                <div style={{
                  "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                }} className="w-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] p-0.5 rounded-3xl">
                  <div className="bg-white rounded-3xl w-full">
                    <img
                      src={map}
                      alt="blog1"
                      className="w-full rounded-t-3xl"
                    />
                    <div className="w-full text-left p-4 lg:p-6">
                      <p
                        style={{
                          "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          "-webkit-background-clip": "text",
                          "-webkit-text-fill-color": "rgba(255,255,255,0)",
                        }}
                        className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] inline-block text-transparent 
                            bg-clip-text text-[18px] font-semibold font-['Poppins']"
                      >
                        Coming in 2025
                      </p>
                      <h2 className="font-medium text-xl lg:text-[30px] font-['Montserrat'] lg:leading-[46px] mt-4">
                        Future Blog
                      </h2>
                      <p className="text-base font-medium font-['Montserrat'] my-4">
                        Benefits of using DeltaClaims.AI.
                      </p>
                      <p
                        style={{
                          "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                          "-webkit-background-clip": "text",
                          "-webkit-text-fill-color": "rgba(255,255,255,0)",
                        }}
                        className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] inline-block text-transparent 
                            bg-clip-text text-[20px] font-semibold font-['Poppins']"
                      >
                        {"Read more >>"}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <img
        src="/assets/images/blog/bottom-wave.png"
        className="absolute left-0 bottom-0 lg:-bottom-[55px] z-10"
        alt="shape"
      />
    </div>
  );
};

export default Blogs;
