import React, { useEffect } from "react";

// Modal component, accepts two props: `show` (whether modal is visible) and `onClose` (function to close the modal)
const Modal = ({ show, onClose }) => {
    // useEffect to create the HubSpot form when the modal is shown
    useEffect(() => {
        if (show) {
            // Load the HubSpot form
            document.body.style.overflow = 'hidden';
            window.hbspt.forms.create({
                region: "na1",
                portalId: "47142898",
                formId: "0a5333d1-4be9-49dd-9b80-e039715d9235",
                target: "#hubspotForm"
            });
        } else {
            // Clean up the form when the modal is closed
            document.body.style.overflow = 'auto';
            const formElement = document.getElementById('hubspotForm');
            if (formElement) {
                formElement.innerHTML = '';
            }
        }

        // Cleanup function to unlock body scroll when component unmounts
        return () => {
            document.body.style.overflow = 'auto';
        };
            
    }, [show]);

    // Don't render anything if the modal is not shown
    if (!show) return null;

    // Render the modal
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[300] p-4">
            {/* Modal container */}
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg relative max-h-[80vh] overflow-auto">
                {/* Close button inside the modal, positioned top-right of the modal */}
                <button
                    className="absolute top-2 right-2 text-white bg-[#0095D9] border-[#0095D9] text-[12px] leading-[12px] font-montserrat p-2 rounded"
                    onClick={onClose}
                >
                    Close
                </button>
                {/* HubSpot form will be rendered here */}
                <div id="hubspotForm" className="mt-8 flex-grow"></div>
            </div>
        </div>
    );
};

// Export the Modal component to be used in other files
export default Modal;