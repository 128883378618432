import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LogIn = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const cognitoDomain = 'auth.deltaclaims.ai';
  const clientId = '4lv3t219e5eb9barflut8vm5kf';
  const redirectUri = 'https://portal-stage.deltaclaims.ai/callback';
  const responseType = 'code';  // For Authorization Code Grant
  const loginUrl = `https://${cognitoDomain}/login?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}`;

  const navigate = useNavigate();

  const handleLogin = () => {
    window.location.href = loginUrl;
  };

  return (
    <div className="w-full bg-[#f3fff4] relative">
      <div className="w-full relative">
        <img
          alt="banner"
          src="/assets/images/common-banner.png"
          className="w-full relative h-[150px] lg:h-[550px]"
        />
        <div className="w-full p-4 absolute top-0 lg:top-[250px] left-0 right-0 mx-auto">
          <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] leading-[70px] text-white inline-block relative">
            Log In
            <span className="font-bold relative ml-3 z-10">
              <img
                alt="line"
                src="/assets/images/blog/line-through.png"
                className="w-full absolute top-[-6px] lg:-top-[20px] left-0 z-[-1]"
              />
            </span>
          </h1>
        </div>
      </div>

      <div className="w-full max-w-screen-xl mx-auto my-10 border-b-2 border-[#d9d9d9] py-10">
        <div className="w-full p-4">
          <h2 className="text-center text-xl lg:text-[22px] font-bold">Welcome back to DeltaClaims.AI</h2>
          <p className="text-center text-md lg:text-lg mt-4">Please use the link below to access the secure login portal</p>

          <div className="text-center mt-12">
            <button
              onClick={handleLogin}
              className="px-6 py-3 text-white bg-blue-500 rounded hover:bg-blue-600"
            >
              Log In
            </button>
          </div>


          <div className="text-center mt-24">
            <span className="text-md lg:text-md">
                Don't have an account yet?{" "}
                <span
                  onClick={() => navigate("/pricing")}
                  className="text-blue-500 cursor-pointer hover:underline"
                >
                  Sign Up!
                </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogIn;
