import React from "react";

const GradientText = ({text, classes, icon}) => {
    return(
        <p style={{
            "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
            "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
            "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
            "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
            "-webkit-background-clip": "text",
            "-webkit-text-fill-color": "rgba(255,255,255,0)",
        }} className={classes + " " + "bg-gradient-to-r from-[#59bb66] to-[#0195d8] inline-block text-transparent bg-clip-text text-[22px] font-medium font-['Poppins'] tracking-widest"}>{text}</p>
    );
}

export default GradientText;