import GradientText from "../../components/ui/gradient-text";
import React, { useState } from "react";
import Button from "../../components/ui/button";
import { behavior } from "@testing-library/user-event/dist/cjs/event/behavior/registry.js";

// HubSpot Form Component
const HubSpotForm = ({ onFormSubmit }) => {
    React.useEffect(() => {
        // Check if the HubSpot form is available and create the form
        if (window.hbspt) {
            window.hbspt.forms.create({
                portalId: "47142898",
                formId: "0a5333d1-4be9-49dd-9b80-e039715d9235",
                target: "#hubspotForm",
                onFormSubmit: onFormSubmit,
            });
        }

        // Cleanup to remove the form on component unmount
        return () => {
            const formContainer = document.getElementById("hubspotForm");
            if (formContainer) {
                formContainer.innerHTML = ""; // Clear form data
            }
        };
    }, [onFormSubmit]);

    return <div id="hubspotForm" />;
};

// Contact Us View
const ContactUs = () => {
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleFormSubmit = () => {
        setFormSubmitted(true);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleLoginSignup = () => {
        window.location.href = '/login-signup';
    };

    // Return the Contact Us view
    return (
        <div className="w-full relative bg-[#001235]">
            <img
                alt="banner"
                src="/assets/images/home/home-banner-shadow.png"
                className="absolute z-40 hidden xl:block"
            />
            <div className="w-full relative z-50">
                <img
                    alt="banner"
                    src="/assets/images/team/team-shape-banner.png"
                    className="hidden md:block w-full relative h-[150px] lg:h-[550px]"
                />
                <img
                    alt="banner"
                    src="/assets/images/common-banner.png"
                    className="block md:hidden w-full relative h-[150px] lg:h-[550px]"
                />
                <div className="w-full p-4 absolute top-0 lg:top-[250px] left-0 right-0 mx-auto z-50">
                    <h1 className="text-2xl text-white lg:text-[60px] font-['Montserrat'] capitalize lg:leading-[70px]">
                        contact
                        <span className="font-bold relative ml-3">
                            Us
                            <img
                                src="/assets/images/home/line-through.png"
                                alt="sol"
                                className="absolute left-0 top-0 w-full h-full z-50"
                            />
                        </span>
                    </h1>
                </div>
            </div>

            {/* Contact Us Form */}
            <div className="w-full relative bg-[#001235] pt-20">
                <img
                    src="/assets/images/team/team-right-shape.png"
                    className="clipped-image absolute top-[-220px] right-0 hidden md:block z-[5]"
                    alt="team-right-shape" />

                <div className="w-full text-center flex items-center justify-center gap-2 relative z-50">
                    <img src="/assets/images/home/color-favi.png" alt="favi" />
                    <GradientText
                        classes={"uppercase text-center font-medium text-xl lg:text-[22px]"}
                        text={"reach out"}
                    />
                </div>
                <div className="w-full text-center flex items-center justify-center gap-2 relative z-50">
                    <h2 className="text-2xl lg:text-[50px] text-white font-['Montserrat'] lg:leading-[55px] py-3 z-50">
                        Please Fill Out
                        <span className="font-bold ml-3">
                            Our Form
                        </span>
                    </h2>
                </div>
                {/* HubSpot Form */}
                <div className="w-full max-w-screen-xl mx-auto lg:pt-10 p-4 sm:p-6 lg:p-8 relative z-50">
                    {!formSubmitted ? (
                        <div
                            style={{
                                background: "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                background: "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                background: "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                background: "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                            }}
                            className="p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] rounded-lg"
                        >
                            <div className="bg-white rounded-lg p-4 sm:p-6 lg:p-8">
                                <HubSpotForm onFormSubmit={handleFormSubmit} />
                            </div>
                        </div>
                    ) : (
                        <div
                            style={{
                                background: "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                background: "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                background: "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                background: "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                            }}
                            className="p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] rounded-lg"
                        >
                            <div className="bg-white rounded-lg p-4 sm:p-6 lg:p-8 text-center">
                                <p className="text-base font-['Montserrat'] text-[#001235]">
                                    Thanks for reaching out!<br/>
                                    Someone will get back to you shortly.
                                </p>
                            </div>
                        </div>
                    )}

                    {/* Login/Signup Button */}
                    <div className="flex pt-4 flex-wrap items-center gap-4 w-full justify-center mt-4 lg:mt-0">
                        <Button
                            type={"button"}
                            text={"Log In / Sign Up"}
                            fill={true}
                            icon={true}
                            onClick={handleLoginSignup}
                        />
                    </div>
                </div>

                <img src="/assets/images/team/team-pixel.png" className="relative bottom-[-50px] sm:bottom-[-75px] lg:-bottom-[130px] xl:-bottom-[200px] bg-[#001235] w-full left-0" alt="pixel" />
            </div>
        </div>
    );
}

export default ContactUs;
