import Button from "../../components/ui/button";
import GradientText from "../../components/ui/gradient-text";
import React from "react";

const Team = () => {

    const members = [
        {
            name: 'Will Perez, Ph.D.',
            title: 'Co-Founder, CTO',
            img: '/assets/images/team/team1-blank.png',
        },
        {
            name: 'Don McChesney',
            title: 'Co-Founder',
            img: '/assets/images/team/team1-blank.png',
        },
        {
            name: 'Michael Deckelman',
            title: 'Chief Operating Officer',
            img: '/assets/images/team/team1-blank.png',
        },
        {
            name: 'Daniel Seuzeneau',
            title: 'Chief Marketing Officer',
            img: '/assets/images/team/team1-blank.png',
        }

    ]

    const icons = [
        '/assets/images/team/fb.svg',
        '/assets/images/team/linkedin.svg',
        '/assets/images/team/twitter.svg',
    ]

    return (
        <div className="w-full relative bg-[#001235]">
            <img
                alt="banner"
                src="/assets/images/home/home-banner-shadow.png"
                className="absolute left-0 top-0 z-50 hidden xl:block"
            />
            <div className="w-full relative bg-[#001235]">
                <img
                    alt="banner"
                    src="/assets/images/team/team-shape-banner.png"
                    className="w-full  absolute -bottom-8 lg:relative z-10 lg:h-[750px]"
                />
                <div className="w-full p-4 lg:absolute top-0 left-0 right-0 mx-auto h-full z-10">
                    <div className="w-full max-w-screen-xl mx-auto flex flex-wrap lg:flex-nowrap items-center flex-wrap md:flex-nowrap h-full">
                        <div className="w-full lg:w-5/12 text-white text-center lg:text-left relative z-10">
                            <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] capitalize lg:leading-[70px] mb-8">
                                meet the
                                <span className="font-bold relative ml-3">
                                    Team
                                    <img
                                        src="/assets/images/home/line-through.png"
                                        alt="sol"
                                        className="absolute left-0 top-0 w-full h-full z-[-1]"
                                    />
                                </span>
                            </h1>
                            {/*<p className="text-base font-['Montserrat'] py-[30px]">
                                it is a long established fact that a reader will it is a long
                                established fact that a reader will it is a long established
                                fact that a reader will
                            </p>*/}
                            <Button
                                fill={true}
                                text={"get started with us"}
                                type={"button"}
                                icon={true}
                            />
                        </div>
                        <div className="w-full lg:w-6/12 lg:pt-[100px] relative z-10">
                            <img
                                alt="banner"
                                src="/assets/images/team/team-banner.png"
                                className="w-full"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full relative bg-[#001235] pt-20">
                <img src="/assets/images/team/team-right-shape.png" className="absolute top-[-200px] right-0" alt="team-right-shape" />

                <div className="w-full text-center flex items-center justify-center gap-2 relative z-40">
                    <img src="/assets/images/home/color-favi.png" alt="favi" />
                    <GradientText
                        classes={"uppercase text-center font-medium text-xl lg:text-[22px]"}
                        text={"our best team"}
                    ></GradientText>
                </div>
                <h2 className="text-2xl lg:text-[50px] text-white font-['Montserrat'] lg:leading-[55px] py-3 ">
                    Meet The
                    <span className="font-bold ml-3">Team</span>
                </h2>

                <div className="w-full max-w-screen-xl mx-auto lg:pt-10 p-4">
                    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-5">
                        {
                            members.map((member, index) => {
                                return (
                                    <div key={index} className="w-full">
                                        <div style={{
                                            "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                            "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                            "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                            "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                        }} className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] p-0.5 rounded-3xl">
                                            <div className="w-full rounded-3xl bg-[#0d1e3f] pt-4 pr-4 pl-4 overflow-hidden relative">
                                                {index === 0 && <img src="/assets/images/team/team1-shadow.png" alt="shadow" className="absolute left-[-140px] top-[-140px]" />}
                                                {index === 2 && <img src="/assets/images/team/team3-shadow.png" alt="shadow" className="absolute right-[-140px] top-[-140px]" />}
                                                <img src={member.img} className="block mx-auto relative z-10 w-full object-contain object-bottom lg:h-[430px]" alt="team" />
                                            </div>
                                        </div>
                                        <p className="w-full text-[30px] font-semibold font-['Montserrat'] mt-6 mb-4 text-white">
                                            {member.name}
                                            <br></br>
                                            {member.title}
                                        </p>
                                        {/*<p className="text-base font-['Montserrat'] text-white">
                                            It is a long established fact that a reader will be distracted by the readable content.
                                        </p>*/}
                                        <div className="flex items-center gap-4 justify-center mt-4">
                                            {
                                                icons.map((icon, index) => {
                                                    return (
                                                        <div style={{
                                                            "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                                            "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                                            "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                                            "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                                                          }} key={index} className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-[50px] h-[50px] p-3 rounded-full flex items-center justify-center">
                                                            <img className="w-full text-white" src={icon} alt="icon" />
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <img src="/assets/images/team/team-pixel.png" className="relative bottom-[-50px] sm:bottom-[-75px] lg:-bottom-[130px] xl:-bottom-[200px] bg-[#001235] w-full left-0" alt="pixel" />
            </div>
        </div>
    );
}

export default Team;