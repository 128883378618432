import React, { useState } from "react";
import Button from "../ui/button";
import { NavLink, useLocation } from "react-router-dom";
import Modal from "../../Modal";

const Header = () => {
  const loc = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  
  const toggleMenu = () => {
    const el = document.getElementById("mobile-menu");
    if (el.classList.contains("hidden")) {
      el.classList.remove("hidden");
    } else {
      el.classList.add("hidden");
    }
  };

  const handleContactUsClick = () => {
    window.scrollTo(0, 0);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <nav className="px-4 py-[30px] w-full bg-[#001235] xl:bg-transparent">
      <div className="flex flex-wrap xl:flex-nowrap items-center justify-between items-center mx-auto max-w-[1800px] w-full">
        <div className="w-full justify-center xl:justify-start flex items-center flex-wrap xl:flex-nowrap 2xl:w-8/12 gap-4 xl:gap-4 2xl:gap-x-[80px] logo-main relative z-[200]">
          <NavLink to={"/"}>
            <img
              src="/assets/images/logo.png"
              className="block mx-auto"
              alt="Logo"
            />
          </NavLink>
          <button
            type="button"
            onClick={toggleMenu}
            style={{
              "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
            }}
            className="inline-flex items-center p-2 ml-1 text-sm text-white rounded-lg xl:hidden 
              focus:outline-none bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-fit focus:ring-2 focus:ring-[#0195d8]
              absolute top-0 right-2"
          >
            <svg
              className="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          <div
            className="fixed hidden xl:relative top-0 left-0 h-full z-[100] bg-[#001235] bg-opacity-95 xl:bg-transparent 
            justify-between items-center w-full xl:flex xl:w-auto text-base font-['Poppins'] z-[110]"
            id="mobile-menu"
          >
            <ul className="flex flex-col font-medium xl:flex-row gap-6 xl:gap-[60px] text-left p-6 xl:p-0">
              <button
                onClick={toggleMenu}
                type="button"
                style={{
                  "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                }}
                className="inline-flex items-center p-2 ml-1 text-sm text-white rounded-lg xl:hidden 
              focus:outline-none bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-fit focus:ring-2 focus:ring-[#0195d8]
              w-fit ml-auto"
              >
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              <li onClick={toggleMenu}>
                <NavLink to="/" className="block py-2 text-white">
                  Home
                </NavLink>
                {loc.pathname === "/" && (
                  <hr style={{
                    "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  }} className="w-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-px mt-2 border-0"></hr>
                )}
              </li>
              <li onClick={toggleMenu}>
                <NavLink to="/our-solution" className="block py-2 text-white">
                  Our Solutions
                </NavLink>
                {loc.pathname === "/our-solution" && (
                  <hr style={{
                    "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  }} className="w-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-px mt-2 border-0"></hr>
                )}
              </li>
              <li onClick={toggleMenu}>
                <NavLink to="/testimonials" className="block py-2 text-white">
                  Testimonials
                </NavLink>
                {loc.pathname === "/testimonials" && (
                  <hr style={{
                    "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  }} className="w-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-px mt-2 border-0"></hr>
                )}
              </li>
              <li onClick={toggleMenu}>
                <NavLink to="/blog" className="block py-2 text-white">
                  Blogs
                </NavLink>
                {loc.pathname === "/blog" && (
                  <hr style={{
                    "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  }} className="w-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-px mt-2 border-0"></hr>
                )}
              </li>
              <li onClick={toggleMenu}>
                <NavLink to="/team" className="block py-2 text-white">
                  Meet The Team
                </NavLink>
                {loc.pathname === "/team" && (
                  <hr style={{
                    "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  }} className="w-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-px mt-2 border-0"></hr>
                )}
              </li>
              <li onClick={toggleMenu}>
                <NavLink to="/what-is-ai" className="block py-2 text-white">
                  What Is AI
                </NavLink>
                {loc.pathname === "/what-is-ai" && (
                  <hr style={{
                    "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  }} className="w-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-px mt-2 border-0"></hr>
                )}
              </li>
              <li className="xl:hidden text-center lg:text-left" onClick={toggleMenu}>
                <NavLink to="/login-signup" className="block py-2 text-white">                
                  <Button type={"button"} text={"Log In / Sign Up"} />
                </NavLink>
              </li>
              <li className="xl:hidden">
                <Button
                  type={"button"}
                  text={"Contact Us"}
                  fill={true}
                  onClick={() => {
                    handleContactUsClick();
                    toggleMenu();
                  }}
                />
              </li>
            </ul>
          </div>
        </div>

        <div className="hidden xl:flex items-center justify-end flex-wrap flex-col 2xl:flex-row xl:flex-nowrap gap-4 items-center w-full xl:w-[230px] 2xl:w-4/12 button-main">
          <NavLink to="/login-signup">
            <Button type={"button"} text={"Log In / Sign Up"} onClick={toggleMenu} />
          </NavLink>
          <Button
            type={"button"}
            text={"Contact Us"}
            fill={true}
            onClick={() => {
              handleContactUsClick();
              toggleMenu();
            }}
          />
        </div>
      </div>

        {isModalOpen && <Modal show={isModalOpen} onClose={closeModal} />}
    </nav>
  );
};

export default Header;
