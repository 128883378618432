import React from "react";
import Button from "../../components/ui/button";
import GradientText from "../../components/ui/gradient-text";

const WhatIsAi = () => {
  const content = [
    "Import data",
    "Classifies, separates, and organizes the data",
    "Studies the content",
    "Validates the content",
    "You ask the questions",
    "Populates the tool with the most consistently needed information",
    "It provides the answers",
  ];

  const content_right = [
    "Draft emails or letters (connects Outlook or Gmail accounts to draft, send, and track correspondence within AI)",
    "Create tasks with reminders for you to follow up",
    "Review a variance report that provides a chronological summary of the estimates by area or trade",
    "Review a summary report specific to this claim",
    "Ask the bot your questions and get specific responses to your questions with a link to the documents that the answer pertains to",
  ];
  return (
    <div className="w-full relative bg-[#001235]">
      <img
        alt="banner"
        src="/assets/images/home/home-banner-shadow.png"
        className="absolute left-0 top-0 z-50 hidden lg:block"
      />
      <div className="w-full relative bg-[#001235]">
        <img
          alt="banner"
          src="/assets/images/team/team-shape-banner.png"
          className="w-full relative z-10 h-[750px] lg:h-[750px] md:h-[400px] object-cover xl:object-fill"
        />
        <div className="w-full p-4 absolute top-0 left-0 right-0 mx-auto h-full z-10">
          <div className="w-full max-w-screen-xl mx-auto flex fex-wrap lg:flex-nowrap items-center justify-center lg:justify-start flex-wrap md:flex-nowrap h-full">
            <div className="w-full lg:w-5/12 text-white text-center lg:text-left">
              <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] capitalize lg:leading-[70px]">
                what is
                <span className="font-bold relative ml-3">
                  AI
                  <img
                    src="/assets/images/what_is_ai/ai_circle.png"
                    alt="sol"
                    className="absolute left-0 top-0 w-full h-full"
                  />
                </span>
              </h1>
              <p className="text-base font-['Montserrat'] py-[30px]">
              Artificial Intelligence (AI) refers to technology that enables machines to perform tasks that typically require human intelligence, such as understanding language, recognizing images, and making decisions. AI can help automate repetitive processes, analyze large amounts of data quickly, and improve customer experiences through personalized recommendations or support. It’s like giving your software the ability to think and learn, helping your business work smarter and more efficiently.
              </p>
              <Button
                fill={true}
                text={"get started with us"}
                type={"button"}
                icon={true}
              />
            </div>
            <div className="w-ful lg:w-6/12 lg:pt-[100px]">
              <img alt="banner" src="/assets/images/team/team-banner.png" className="w-full max-w-[500px] lg:max-w-full" />
            </div>
          </div>
        </div>
      </div>

      <div className="w-full relative bg-[#001235] p-4 lg:pt-10">
        <img
          src="/assets/images/what_is_ai/shadow-effect.png"
          className="absolute top-[-400px] right-0"
          alt="shadow"
        />
        <img
          src="/assets/images/team/team-right-shape.png"
          className="absolute top-[-200px] right-0"
          alt="team-right-shape"
        />
        <img
          src="/assets/images/what_is_ai/side_effect.png"
          className="absolute top-[800px] right-0"
          alt="shadow"
        />
        <img
          src="/assets/images/what_is_ai/what-is-ai.png"
          className="w-full max-w-[2000px] mx-auto absolute top-[100px] left-0 right-0"
          alt="what_is_ai"
        />

        <div className="w-full max-w-screen-xl mx-auto pt-10 pb-20 lg:pb-[200px] 2xl:pb-[300px] relative z-30">
          <div className="w-full text-center flex items-center justify-center gap-2">
            <img src="/assets/images/home/color-favi.png" alt="favi" />
            <GradientText
              classes={"uppercase text-center font-medium text-xl lg:text-[22px]"}
              text={"ai claims"}
            ></GradientText>
          </div>
          <h2 className="text-2xl lg:text-[50px] text-white font-['Montserrat'] lg:leading-[55px] py-3 ">
            What Is
            <span className="font-bold ml-3">AI.</span>
          </h2>
          <p className="text-base font-['Montserrat'] text-white w-full max-w-6xl mx-auto">
            AI uses information from thousands of claims files to get you the
            information you need. AI learns from all of the users to focus
            on providing you what is important. AI figures out how people
            act, how things are connected, and why things happen across the
            claims industry. This information helps you to make better decisions
            about how to pursue the claims you are working.
          </p>

          <img
            src="/assets/images/what_is_ai/what-main.png"
            className="w-full relative z-10"
            alt="what_is_ai"
          />

          <div className="grid md:grid-cols-2 gap-6 -mt-[55px] lg:-mt-[240px]">
            <div style={{
              "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
            }} className="p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-full rounded-3xl h-full">
              <div className="rounded-3xl w-full bg-[#0d1e3f] p-4 lg:p-[40px] pb-[100px] h-full flex flex-col justify-between relative overflow-hidden">
                {content.map((data, index) => {
                  return (
                    <div
                      className={`flex items-center gap-6 w-full ${index < content.length - 1 && "border-b"} border-[#21585c] py-6 text-white`}
                    >
                      <p
                        className="text-[20px] font-bold font-['Montserrat'] bg-gradient-to-r from-[#59bb66] 
                                    to-[#0195d8] w-[45px] h-[45px] flex items-center justify-center rounded-full flex-shrink-0"
                      >
                        {index + 1}
                      </p>
                      <p className="text-base font-['Montserrat'] text-left">
                        {data}
                      </p>
                    </div>
                  );
                })}

                <img
                  src="/assets/images/what_is_ai/bottom-shade-what.png"
                  className="absolute -bottom-[400px] w-full left-0 right-0 mx-auto"
                  alt="pixel"
                />
              </div>
            </div>
            <div style={{
              "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
            }} className="p-0.5 bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-full rounded-3xl relative h-full">
              <div style={{
                "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
              }} className="absolute w-fit mx-auto -top-2 left-0 right-0 py-1 px-4 bg-gradient-to-r from-[#59bb66] to-[#0195d8] rounded-md z-10">
                <p className="text-white text-xs lg:text-base font-['Montserrat']">
                  AI generates answers that then allow it to{" "}
                </p>
              </div>
              <div className="rounded-3xl w-full bg-[#0d1e3f] p-4 lg:p-[40px] pb-[100px] h-full flex flex-col justify-between relative overflow-hidden">
                {content_right.map((data, index) => {
                  return (
                    <div
                      className={`flex items-center gap-6 w-full ${index < content_right.length - 1 && "border-b"} border-[#21585c] py-10 text-white`}
                    >
                      <p
                        className="text-[20px] font-bold font-['Montserrat'] bg-gradient-to-r from-[#59bb66] 
                                    to-[#0195d8] w-[45px] h-[45px] flex items-center justify-center rounded-full flex-shrink-0"
                      >
                        {index + 1}
                      </p>
                      <p className="text-base font-['Montserrat'] text-left">
                        {data}
                      </p>
                    </div>
                  );
                })}
                <img
                  src="/assets/images/what_is_ai/green-bottom-shade.png"
                  className="absolute -bottom-[400px] w-full left-0 right-0 mx-auto"
                  alt="pixel"
                />
              </div>
            </div>
          </div>
        </div>

        <img
          src="/assets/images/what_is_ai/bottom-what.png"
          className="absolute -bottom-[30px] lg:-bottom-[130px] bg-[#001235] w-full left-0"
          alt="pixel"
        />
      </div>
    </div>
  );
};

export default WhatIsAi;
