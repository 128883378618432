import React from "react";

function Button({ text, type, fill, icon, onClick }) {
  if (fill) {
    return (
      <button
        style={{
          "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
          "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
          "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
          "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
        }}
        type={type}
        className="bg-gradient-to-r from-[#59bb66] to-[#0195d8] hover:from-[#0195d8] hover:to-[#59bb66]
        focus:ring-4 focus:ring-[#0195d8] focus:outline-none p-2 rounded-full text-white w-full max-w-[250px] h-[50px] mx-auto lg:mx-0
        text-base font-['Poppins'] font-medium capitalize transition-all duration-300 flex-shrink-0 flex items-center justify-center gap-x-2"
        onClick={onClick}
      >
        {icon && <img src="/assets/images/favi.png" />}
        {text}
      </button>
    );
  } else {
    return (
      <button
        type={type}
        className="border-2 border-white focus:ring-4 focus:ring-[#0195d8] focus:outline-none p-2 
        rounded-full text-white w-full max-w-[250px] h-[50px] text-base font-['Poppins'] font-medium capitalize flex-shrink-0"
        onClick={onClick}
      >
        {text}
      </button>
    );
  }
}

export default Button;
