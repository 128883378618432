import React from "react";
import GradientText from "../../components/ui/gradient-text";
import Button from "../../components/ui/button";

const Testimonial = () => {
  const images = [
    //"/assets/images/home/b1.png",
    "/assets/images/home/b2.png",
    //"/assets/images/home/b3.png",
  ];

  const inviduals = [
    {
      icon: "/assets/images/testimonial/icon1.png",
      text: "General Contractors",
    },
    {
      icon: "/assets/images/testimonial/icon2.png",
      text: "Adjusters",
    },
    {
      icon: "/assets/images/testimonial/icon3.png",
      text: "Attorneys",
    },
  ];

  const professionals = [
    {
      image: "/assets/images/testimonial/i1.png",
      name: "Nathan Boudreaux",
    },
    {
      image: "/assets/images/testimonial/i2.png",
      name: "Jackson Tate",
    },
  ];

  return (
    <div className="w-full bg-[#f3fff4] relative">
      <div className="w-full relative">
        <img
          alt="banner"
          src="/assets/images/testimonial/testimonial-banner.png"
          className="w-full relative h-[150px] lg:h-[550px] hidden xl:block"
        />
        <img
          alt="banner"
          src="/assets/images/common-banner.png"
          className="w-full relative h-[150px] lg:h-[550px] xl:hidden"
        />
        <div className="w-full p-4 absolute top-0 lg:top-[250px] left-0 right-0 mx-auto">
          <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] leading-[70px] text-white inline-block relative">
            Client
            <span className="font-bold relative ml-3 z-10">
              Testimonials
              <img
                alt="line"
                src="/assets/images/blog/line-through.png"
                className="w-full absolute top-[-6px] lg:-top-[20px] left-0 z-[-1]"
              />
            </span>
          </h1>
        </div>
      </div>

      <div className="w-full relative z-10 p-4">
        <div className="w-full max-w-screen-xl mx-auto my-10 border-b-2 border-[#d9d9d9] py-10">
          <GradientText
            classes={"uppercase text-center font-bold text-lg lg:text-[22px]"}
            text={"trusted by leading companies"}
          ></GradientText>
        {/* removed image and added coming soon text*/}
        <div className="w-full max-w-screen-xl mx-auto py-10 lg:py-0 my-10 p-4">
          <GradientText
            classes={"text-center font-bold text-lg lg:text-[22px]"}
            text={"Coming Soon"}
          ></GradientText>
        </div>
        </div>
      </div>

      <div className="w-full max-w-screen-xl mx-auto lg:mt-24 relative z-10 p-4 xl:p-0">
        <div className="justify-center flex gap-4 items-center">
          <img
            src="/assets/images/blog/color-favi.png"
            alt="color-favi"
            className="lg:w-12"
          />
          <GradientText
            classes={"uppercase text-center font-medium text-xl lg:text-[22px]"}
            text={"individuals ai"}
          ></GradientText>
        </div>
        <h2 className="text-2xl lg:text-[50px] font-['Montserrat'] leading-9 lg:leading-[55px] py-3 ">
          Individuals That <span className="font-bold">Use Our AI</span>
        </h2>
        <div className="flex flex-wrap gap-6 lg:gap-0 lg:flex-nowrap pt-8 pb-20 justify-center relative z-10">
          {inviduals.map((inv) => {
            return (
              <div className="w-full">
                <div style={{
                  "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                }} className="p-0.5 rounded-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-full max-w-[155px] h-[155px] block mx-auto mb-4">
                  <div className="w-full bg-white rounded-full h-full flex items-center justify-center">
                    <img src={inv.icon} alt={inv.icon} />
                  </div>
                </div>
                <p className="text-[25px] font-semibold font-['Montserrat']">
                  {inv.text}
                </p>
              </div>
            );
          })}
        </div>

        <h2 className="text-[30px] font-['Montserrat'] lg:leading-[55px] py-[30px]">
          Trusted By <span className="font-bold">Industry</span> Professionals
        </h2>
        <div className="grid md:grid-cols-2 gap-8 mb-10">
          {professionals.map((prof) => {
            return (
              <div className="w-full">
                <div style={{
                  "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                }} className="p-0.5 rounded-2xl bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-full block mx-auto mb-4 relative">
                  <div className="w-full bg-white rounded-2xl h-full">
                    <img
                      className="rounded-2xl w-full h-full object-cover object-top"
                      src={prof.image}
                      alt={prof.image}
                    />
                  </div>
                  <div style={{
                    "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  }} className="rounded-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] h-[55px] w-[55px] absolute right-10 bottom-10 p-4">
                    <img className="w-full h-full"
                      src="/assets/images/testimonial/play.svg"
                      alt="play"
                    />
                  </div>
                </div>
                <div className="flex gap-4 items-center mt-8">
                  <div style={{
                    "background": "-webkit-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-o-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "-moz-linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                    "background": "linear-gradient(90deg, rgb(89,187,102) 25%, rgb(1,149,216) 75%)",
                  }} className="p-0.5 rounded-full bg-gradient-to-r from-[#59bb66] to-[#0195d8] w-full max-w-[55px] h-[55px]">
                    <div className="w-full bg-white rounded-full h-full">
                      <img src={prof.image} alt={prof.image} className="object-cover object-top w-full rounded-full h-full" />
                    </div>
                  </div>
                  <p className="font-bold text-[30px] font-['Montserrat']">{prof.name}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-full flex justify-center pb-10">
          <Button text={'get access'} icon={true} fill={true} />
        </div>
      </div>

      <img
        src="/assets/images/testimonial/shape-left.png"
        className="absolute left-0 bottom-[20%]"
        alt="shape"
      />
      <img
        src="/assets/images/testimonial/shape-right.png"
        className="absolute right-0 bottom-12"
        alt="shape"
      />
    </div>
  );
};

export default Testimonial;
