import React, { useEffect } from "react";

const PrivacyPolicy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

  return (
    <div className="w-full bg-[#f3fff4] relative">
      <div className="w-full relative">
        <img
          alt="banner"
          src="/assets/images/common-banner.png"
          className="w-full relative h-[150px] lg:h-[550px]"
        />
        <div className="w-full p-4 absolute top-0 lg:top-[250px] left-0 right-0 mx-auto">
          <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] leading-[70px] text-white inline-block relative">
            Privacy Policy
            <span className="font-bold relative ml-3 z-10">
              <img
                alt="line"
                src="/assets/images/blog/line-through.png"
                className="w-full absolute top-[-6px] lg:-top-[20px] left-0 z-[-1]"
              />
            </span>
          </h1>
        </div>
      </div>

      <div className="w-full max-w-screen-xl mx-auto my-10 border-b-2 border-[#d9d9d9] py-10">
        <div className="w-full p-4">
          <h2 className="text-center text-xl lg:text-[22px] font-bold">Privacy Policy</h2>
          
          <p className="text-left text-md lg:text-lg mt-6 font-bold">1. <u>Introduction</u></p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">This privacy policy discloses how Deltaclaims.AI, LLC (hereinafter referred to as “DeltaClaims”), uses and protects any information that you give when you use the <a href="https://www.deltaclaims.ai" style={{ color: 'blue', textDecoration: 'underline' }}>www.deltaclaims.ai</a> website and any and all programs and platforms offered thereon (collectively, the “Website”), including what type of personal identifiable information is collected, how the information is used, and with whom the information is shared.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">DeltaClaims may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you review any and all changes. This policy is effective from October 1, 2024.</p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">2. <u>Personal Information That May Be Collected</u></p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(a) Account Identification Information.</strong> In order to access designated subscriber services, accounts, and/or restricted areas within the Website, DeltaClaims will request a user to provide certain personal identifying information, which may include: first and last name, e-mail address, username and password, phone number, and geolocation information as set forth below. DeltaClaims may request additional information necessary to establish and maintain a user's account, enable a user to access and/or use all features of the Website, or otherwise render requested services to a user.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(b) Information from Children.</strong> DeltaClaims does not collect or post personally identifying information from a child under the age 13 without the involvement of a parent or guardian.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(c) Device Information.</strong> When a user visits, accesses, or uses the Website, we collect device ID/name and model. We use this information for device identification to render requested services to a user. Our server automatically gathers some anonymous information about users, including IP addresses, browser type, language, and the times and dates of web page visits. The data collected is used for server performance analysis and troubleshooting purposes. In addition to the foregoing, users' personal data (such as account information, messages exchanged, etc.) is uploaded to our server and is used for device identification to render requested services to a user, server performance analysis, and troubleshooting purposes. By accessing and using the Website, you hereby consent to the collection and use of your personal data as set forth herein. To opt out from having your personal data stored on our server, please send your request in writing to the physical mailing address identified in Section 10 below.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(d) Geolocation Information.</strong> When a user visits, accesses, or uses the Website, we may collect location data through GPS, WiFi, or cellular triangulation. We may use this information to track the location of a user in order to improve our services and the marketing or advertising on, or of, the Website. We maintain this data only so long as is reasonable or necessary to achieve the foregoing purposes.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(e) Payment Information.</strong> Because a user cannot order or purchase goods or services directly through the Website, we do not collect or store users' payment card details. Users are re-routed to third party websites or payment processors whose use of users' personal information is governed by their respective Privacy Policy.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(f) Links to Other Web Sites.</strong> The Website may contain links to other mobile applications or websites that are not covered by this privacy policy and where information practices may be different from ours. Our provision of a link to any other website or location is for your convenience and does not signify our endorsement of such other website or location or its contents. Please be aware that the terms of this privacy policy do not apply to these outside websites or content, or to any collection of data after you click on links to such outside websites.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(g) Information related to insurance claims.</strong>  DeltaClaims collects information submitted by our users.  The information submitted by the users may contain personally identifiable, confidential, sensitive information, or information pertaining to trade secrets of their customers or third parties that they do business with.  DeltaClaims is not responsible for providing additional security of information provided by customers above and beyond what is generally provided for all documents.  Any documents or information that is subject to restraining orders or confidentiality agreements should not be submitted to DeltaClaims without express knowledge of the parties to that order or agreement. </p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">3. <u>Uses Made of the Information</u></p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(a) Limited Uses Identified.</strong> Without user's prior consent, DeltaClaims will not use your personally identifiable information for any purpose other than that for which it is submitted or as otherwise provided herein. DeltaClaims uses personally identifiable information to establish and maintain users' accounts, fulfill requests, render requested services, reply to inquiries, handle complaints, provide operational notices and in program record-keeping.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(b) Marketing Uses.</strong> Unless user expressly opts out, DeltaClaims reserves the right to provide user with information about the Website, products and services, and related information in which user has indicated an interest.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(c) Stored Information Uses.</strong> DeltaClaims stores the information provided by user. Stored information is used by DeltaClaims and its agent to support user interaction with the Website; to track and analyze the data gathered from Website visits; to identify industry trends, to train the tools the website uses to better performance, and to contact user again about other DeltaClaims services and products.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(d) Online Advertising.</strong> Some companies that help DeltaClaims deliver interactive on-line advertising, such as banner ads, may collect and use information about DeltaClaims' users to help DeltaClaims better understand the types of advertising or promotions that are most appealing to DeltaClaims' users. After it is collected the information is aggregated so it is not identifiable to a specific individual. If, however, user would prefer that these companies not collect such information, please expressly opt out of the same.</p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">4. <u>Disclosure of the Information</u></p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(a) Within Corporate Organization.</strong> DeltaClaims is a Florida limited liability company, with legal entities, business processes, management structures, and technical systems. DeltaClaims may share your personal information within the DeltaClaims corporate organization and may transfer the information to countries in the world where DeltaClaims conducts business. Some countries may provide less legal protection for user personal information.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(b) Mergers and Acquisitions.</strong> Circumstances may arise where for business reasons, DeltaClaims decides to sell, buy, merge or otherwise reorganize its businesses in the United States or some other country. Such a transaction may involve the disclosure of personal identifying information to prospective or actual purchasers, and/or receiving such information from sellers. It is DeltaClaims' practice to seek appropriate protection for information in these types of transactions.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(c) Agents.</strong> DeltaClaims employs or engages other companies and individuals to perform business functions on behalf of DeltaClaims. These persons are provided with personal identifying information required to perform their functions but are prohibited by contract from using the information for other purposes. These persons engage in a variety of functions which include, but are not limited to, removing repetitive information from user lists, analyzing data, providing marketing assistance, processing payments, and providing user goods and services.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(d) Marketing Analysis by Third Parties.</strong> DeltaClaims reserves the right to disclose to third parties personal information about users for marketing analysis; however, any information disclosed will be in the form of aggregate data that does not describe or identify an individual user.  DeltaClaims does not and will not sell user's client information.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(e) Disclosure to Governmental Authorities.</strong> Under certain circumstances, personal information may be subject to disclosure pursuant to judicial or other government subpoenas, warrants or orders, or to resolve disputes and enforce our legal agreements and policies.</p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">5. <u>Use of Computer Tracking Technologies</u></p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(a) Tracking of Personal Information.</strong> The Website is not set up to track, collect or distribute personal information not entered by users other than as provided for herein. Through website access logs DeltaClaims does collect clickstream data and HTTP protocol elements, which generate certain kinds of non-identifying application usage data, such as the number of downloads of, and visits to, the Website. The Website also collects metadata taken from documents submitted through the Website. This information is used for internal purposes by technical support staff for research and development, user analysis and business decision making, all of which provides better services to our users. The statistics garnered, which contain no personal information and cannot be used to gather such information, may also be provided to third parties.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(b) Use of Cookies.</strong> DeltaClaims, or its third-party vendors, collects non-identifiable and personal information through the use of various technologies, including "cookies". A cookie is an alphanumeric identifier that a web site or mobile application can transfer to a user's hard drive through the user's browser to keep users logged in and save users' visit preferences. The cookie is then stored on user's computer as an anonymous tag that identifies the user's computer, but not the user. Cookies may be sent by DeltaClaims or its third-party vendors. User can set its browser to notify user before a cookie is received, giving an opportunity to decide whether to accept the cookie. User may also set its browser to turn off cookies; however, some web sites or mobile applications may not then work properly.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(c) Use of Web Beacon Technologies.</strong> DeltaClaims may also use Web beacon or other technologies to better tailor its Website to provide better user service. If these technologies are in use, when a visitor accesses these pages of the Website, a non-identifiable notice of that visit is generated which may be processed by DeltaClaims or by its affiliates. Web beacons usually work in conjunction with cookies. If user does not want cookie information to be associated with user's visits to these pages, user can set its browser to turn off cookies; however, Web beacon and other technologies will still detect visits to these pages, but the notices they generate cannot be associated with other non-identifiable cookie information and are disregarded.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(d) Collection of Non-Identifiable Information.</strong> DeltaClaims may collect non-identifiable information from user visits to the Website in order to provide better user service. Examples of such collections include: traffic analysis or tracking numbers of log-ins or visitors; measuring visitor activity on the Website; mobile application and system administration; user analysis; metadata, analysis conducted by the AI, and business decision making. Such information is sometimes known as "clickstream data." DeltaClaims or its contractors may use this data to analyze trends and statistics.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(e) Collection of Personal Information.</strong> DeltaClaims collects personal identifying information from user during use of the services. DeltaClaims may extract some personally identifying information about that transaction in a non-identifiable format and combine it with other non-identifiable information, such as clickstream data. This information is used and analyzed only at an aggregate level (not at an individual level) to help DeltaClaims understand trends and patterns. This information is not reviewed at an individual level.</p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">6. <u>Information Security</u></p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(a) Commitment to Online Security.</strong> DeltaClaims employs physical, electronic and managerial procedures to safeguard the security and integrity of personal information. Personal information is accessible only by staff designated to handle online requests or complaints.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(b) No Liability for Acts of Third Parties.</strong> DeltaClaims will exercise all reasonable efforts to safeguard the confidentiality of user personal information. However, transmissions protected by industry standard security technology and implemented by human beings cannot be made absolutely secure. Consequently, DeltaClaims shall not be liable for unauthorized disclosure of personal information due to no fault of DeltaClaims including, but not limited to, errors in transmission, acts of the user that led to the disclosure, and unauthorized acts of DeltaClaims staff and/or third parties.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">Our goal is to empower claims professionals through innovative, tailor-made AI solutions and to assist insurance professionals and general contractors with efficient, secure, and accurate information about their claim using AI products and/or services (collectively, the “Services”). We strive to maintain a safe platform free from inappropriate and unlawful activities. By accessing the Website or using our Services, you therefore agree not to engage in the conduct described below (or to facilitate or support others in doing so):</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You may not use our Services to do or share anything:</p>
<ul className="list-disc text-left text-md lg:text-lg mt-6 ml-20">
<li>That violates this Privacy Policy, our Terms of Use, and other terms and policies that apply to your use of the Website and Services.</li>
<li>That is unlawful, inappropriate, misleading, discriminatory, disparaging, defamatory, or fraudulent.</li>
<li>That infringes or violates someone else's rights, including their intellectual property rights.</li>
</ul>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You may not upload viruses or malicious code or do anything that could disable, overburden, or impair the proper working or appearance of our Services or the Website.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;You may not access or collect data from the Website using automated means (without our prior permission) or attempt to access data you do not have permission to access.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">We work hard to provide the best Services we can and to specify clear guidelines for everyone who uses them. We do not control or direct what people and others do or say, and we are not responsible for their actions or conduct (whether online or offline) or any content they share (including offensive, inappropriate, obscene, unlawful, and other objectionable content).</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">We cannot predict when issues might arise with our Services. Accordingly, our liability shall be limited to the fullest extent permitted by applicable law, and under no circumstance will we be liable to you for any lost profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to this policy, even if we have been advised of the possibility of such damages. Our aggregate liability arising out of or relating to this policy will not exceed the greater of $100 or the amount you have paid us in the past twelve months.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">We reserve the right to remove or restrict access to content that is in violation of these provisions.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">If we remove content that you have shared in violation of our Terms of Use, we'll let you know and explain any options you have to request another review, unless you seriously or repeatedly violate the Terms or if doing so may expose us or others to legal liability; harm our community of users; compromise or interfere with the integrity or operation of any of our services, systems or products; where we are restricted due to technical limitations; or where we are prohibited from doing so for legal reasons.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">To help support our community, we encourage you to report content or conduct that you believe violates your rights (including intellectual property rights) or our terms and policies.</p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">7. <u>Privacy Policy Changes and Opt-Out Rights</u></p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(a) Changes to Privacy Policy.</strong> This privacy notice was last updated on October 1, 2024. DeltaClaims reserves the right to update its privacy policy statement at any time. A notice of any material change, stating “Our privacy policy has changed. Please review the highlighted change(s),” will be transmitted to all users or posted on the Website home page for thirty (30) days prior to the implementation of such change. There are two boxes at the end of DeltaClaims' notice of change (1) an "I accept" box, and (2) an "I do not accept" box. If user does not mark the "I do not accept" box user will be deemed to have accepted DeltaClaims' privacy policy updates.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(b) Opt-Out Right.</strong> User has the right at any time to cease permitting personal information to be collected, used or disclosed by DeltaClaims and/or by any third parties with whom DeltaClaims has shared and/or transferred such personal information. Right of cancellation may be exercised by contacting DeltaClaims via e-mail, telephone or certified postal mail. After processing the cancellation, DeltaClaims will delete user or prospective user's personal information from its data base.</p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">8. <u>Access Rights to Data</u></p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(a) Information Maintained by DeltaClaims.</strong> Upon user's request, DeltaClaims will provide a reasonable description of user's personally identifiable information that DeltaClaims maintains in its data bank. DeltaClaims can be contacted by e-mail at, telephone, or certified postal mail.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(b) Corrections and Changes to Personal Information.</strong> If user's personal information changes, or if user notes an error upon review of user information that DeltaClaims has on file, please promptly e-mail DeltaClaims and provide the new or correct information.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(c) International Reach.</strong> The Website and the Services are operated in the United States. If you are located outside of the United States, please be aware that any information you provide to us will be transferred to the United States. By providing us with any information through the Website or the Services, you consent to this transfer.</p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">9. <u>California Privacy Rights</u></p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(a) Applicability.</strong> This section applies only to California consumers. For purposes of this section "Personal Information" has the meaning given in the California Consumer Privacy Act (“CCPA”). It describes how we collect, use, and share California consumers' Personal Information in our role as a business, and the rights applicable to such residents. If you are unable to access this Privacy Policy due to a disability or any physical or mental impairment, please contact us and we will arrange to supply you with the information you need in an alternative format that you can access.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(b) How We Collect, Use, and Disclose your Personal Information.</strong> We have collected the following statutory categories of Personal Information in the past twelve (12) months:</p>
<ol className="roman-numeral-list text-left text-md lg:text-lg mt-6">
  <li>Identifiers, such as name, e-mail address, mailing address, and phone number. We collect this information directly from you or from third party sources.</li>
  <li>Commercial information, such as subscription records. We collect this information directly from you.</li>
  <li>Internet or network information, such as browsing and search history. We collect this information directly from your device.</li>
  <li>Geolocation data, such as IP address. We collect this information from your device.</li>
  <li>Financial information, such as Payment Information or financial account numbers in the process of providing you with a subscription. We collect this information from you.</li>
  <li>Professional data, such as your job title and company.</li>
  <li>Other Personal Data, in instances when you interact with us online, by phone or mail in the context of receiving help through our help desks or other support channels; participation in customer surveys or contests; or in providing the Services.</li>
  <li>Inferences drawn from any of the above categories, alone or in combination. </li>
</ol>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">The business and commercial purposes for which we collect this information, the categories of third parties to whom we "disclose" this information for a business purpose, and the period of time for which we retain this information are described in this Privacy Policy. In the preceding 12 months, we have “sold” or “shared” (as defined under the CCPA) certain information, including identifiers, to our advertising partners. We have also “sold” or “shared” certain information, including identifiers, approximate location data, and professional data to our customers.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(c) Your California Rights.</strong> You have certain rights regarding the Personal Information we collect or maintain about you. Please note these rights are not absolute, and there may be cases when we decline your request as permitted by law. These rights include:</p>
<ol className="roman-numeral-list text-left text-md lg:text-lg mt-6">
  <li>The right of access means that you have the right to request that we disclose what Personal Data we have collected and maintained about you in the past 12 months.</li>
  <li>The right of deletion means that you have the right to request that we delete Personal Data collected or maintained by us, subject to certain exceptions.</li>
  <li>The right of correction means that you have the right to request that we correct any inaccurate Personal Data that we maintain about you.</li>
  <li>The right to request information concerning the categories of Personal Data (if any) that we disclose to third parties or affiliates for their direct marketing purposes.</li>
  <li>The right to limit the use of sensitive personal information means that you have the right to direct businesses to only use your sensitive personal information for limited purposes. We only collect sensitive personal information (such as your payment information), as defined by applicable laws for the purposes allowed by law or with your consent. We do not collect or process sensitive personal information for the purpose of inferring characteristics about you.</li>
  <li>The right to appeal means that you can appeal any decision by us to deny your privacy rights under applicable laws.</li>
  <li>The right to non-discrimination means that you will not receive any discriminatory treatment when you exercise one of your privacy rights. </li>
</ol>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">We respond to all appeal requests as soon as we reasonably can, and no later than legally required.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(d) How to Exercise your California Rights.</strong> You can exercise your rights yourself or you can alternatively designate an authorized agent to exercise these rights on your behalf. Please note that to protect your Personal Data, we will verify your identity by a method appropriate to the type of request you are making. Depending on your request, we will ask for information such as your name and your email address. We may also request that your authorized agent have written permission from you to make requests on your behalf, and we may also need to verify your authorized agent's identity to protect your Personal Data. We may deny a request from an agent that does not submit proof that they have been authorized to act on your behalf. </p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">We will respond within the time frame permitted by applicable law. Please note that we may charge a reasonable fee for multiple requests in the same 12-month period, as permitted by law.</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6">Please use the contact details below if you would like to: </p>
<ol className="roman-numeral-list text-left text-md lg:text-lg mt-6">
  <li>Access this policy in an alternative format;</li>
  <li>Exercise any of your rights described above;</li>
  <li>Learn more about your rights or our privacy practices; or</li>
  <li>Designate an authorized agent to make a request on your behalf.</li>
</ol>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(e) Right to Opt Out of “Sale” or “Sharing”.</strong> DeltaClaims may “share” your Personal Data, as defined under the CCPA, with third party advertising partners to deliver personalized ads on Delta Claims' behalf on other companies' websites or mobile applications. Please see Section 4 above for more information. If you'd like your information to be removed from our commercial dataset, or would like to opt out from the “sharing” of your Personal Data for our advertising and marketing activities, please send your request in writing to the physical mailing address identified in Section 10 below. We do not knowingly “sell” or “share” Personal Data of children under 16 years of age.</p>
<p className="text-left text-md lg:text-lg mt-6 font-bold">10. <u>Accountability</u></p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(a) Questions, Problems and Complaints.</strong> If you have a question about this policy statement, or a complaint about DeltaClaims compliance with this privacy policy, you may contact DeltaClaims by mail at the following address:</p>
<p className="text-center text-md lg:text-lg mt-6">Deltaclaims.AI, LLC</p>
<p className="text-center text-md lg:text-lg mt-6">6300 NE 1st Ave Suite 202</p>
<p className="text-center text-md lg:text-lg mt-6">Fort Lauderdale, Florida 33334</p>
<p className="text-left first-line-indent text-md lg:text-lg mt-6"><strong>(b) Terms of Use.</strong> If user chooses to use the Website, user's action is hereby deemed acceptance of DeltaClaims' practices described in this policy statement, our Terms of Use, and other terms and policies that apply to user's use of the Website and Services. Any dispute over privacy between user and DeltaClaims is subject to the provisions of this notice and to DeltaClaims' Terms of Use which is hereby incorporated herein and which can be accessed under the Terms of Use tab on the Website.</p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
